<template lang="pug">
v-card(:loading="isLoading")
  v-card-title(v-if="!isLoading").d-flex.justify-space-between
    h2(v-if="statement").mx-5 {{$t(`statement-srb-from-isc-${Object.keys(statement?.behavior)[0]}`)}} - ID({{statement?.id}})
    ControlButtons(:controlButtons="controlButtons")
  v-card-text
    h2(v-if="statement && statement.sailor && statement.status_document !== rejectStatus").pl-6.text-left {{statement.sailor[`full_${nameLang}`]}} - {{statement.sailor.birth_date}}
    Info(v-if="statement.behavior?.viewInfoBlock" :statement="statement")
    FormAdd(v-else-if="statement.behavior?.viewAddBlock" :statement="statement")
    RejectStatement(v-else-if="statement.behavior?.viewRejectStatementBlock" :statement="statement" @getData="getData")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { viewDetailedComponent } from '@/mixins/main'

export default {
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    FormAdd: () => import('@/views/Sailor/SailorRecordBookStatement/FormAdd'),
    RejectStatement: () => import('./RejectStatement'),
    Info: () => import('./Info')
  },
  data () {
    return {
      // statement: {},
      isLoading: true,
      rejectStatus: 23,
      inProcessingStatus: 25,
      isLoadingControlButtons: false,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-plus-outline',
          action: () => viewDetailedComponent(this.statement, 'viewAddBlock'),
          checkAccess: () => true,
          color: 'green',
          tooltip: 'documentIconControl.add'
        },
        {
          id: 6,
          name: 'mdi-book-cancel-outline',
          action: () => viewDetailedComponent(this.statement, 'viewRejectStatementBlock'),
          checkAccess: () => true,
          color: '#F73E07',
          tooltip: 'setReject'
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'statements-service-record-book-isc' }),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      statement: state => state.statement.statementRecordBookByIdISC
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getStatementRecordBookByIdISC', 'getCommentRejectStatementSRBFromISC']),

    async getData () {
      const { documentID } = this.$route.params
      this.isLoading = true
      const response = await this.getStatementRecordBookByIdISC({ documentID })
      this.isLoading = false
      if ([200, 201].includes(response?.code)) {
        if ([this.rejectStatus, this.inProcessingStatus].includes(response.data.status_document)) {
          this.replaceControlButton()
        }
      }
    },

    replaceControlButton () {
      this.controlButtons = this.controlButtons.filter(el => ![1, 6].includes(el.id))
      this.controlButtons.unshift(
        {
          id: 2,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          checkAccess: () => true,
          color: 'blue',
          tooltip: 'documentIconControl.info'

        }
      )
    }
  }
}
</script>
